import Route from '@ember/routing/route';
import IndexController from './controller';

export default class IndexRoute extends Route {
  public setupController(controller: IndexController, model, transition) {
    super.setupController(controller, model, transition);
    // @ts-ignore
    controller.fetchForecastData.perform();
  }
}
