import { Geom } from '@trovedata/sunstone-ui-commons/types/interfaces/map';
import attr from 'ember-data/attr';
import Model from 'ember-data/model';

export default class Grouping extends Model {
  @attr('boolean') public active: boolean;
  @attr('string') public displayName: string;
  // @ts-ignore
  @attr('json') public geom: Geom;
  @attr('string') public name: string;
  @attr('number') public parentId: number | null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'grouping': Grouping;
  }
}
