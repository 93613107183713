import { layout, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import Grouping from 'esource-storm/models/grouping';
// @ts-ignore
import template from './template';

@layout(template)
@tagName('grouping-info-window')
export default class GroupingInfoWindow extends Component {
  public grouping: Grouping;
  public outageCount: number | null;
}
