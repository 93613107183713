import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SunstoneMapGeojsonComponent from '@trovedata/sunstone-ui-commons/components/sunstone-map-geojson/component';
import TroveFetch from '@trovedata/sunstone-ui-commons/services/trove-fetch';
import { FeatureCollection } from '@trovedata/sunstone-ui-commons/types/interfaces/map';
import buildGeoJson from '@trovedata/sunstone-ui-commons/utils/map-utils';
import { titleize } from '@trovedata/sunstone-ui-commons/utils/string-utils';
import { restartableTask, task } from 'ember-concurrency-decorators';
import Grouping from 'esource-storm/models/grouping';
import Customization from 'esource-storm/services/customization';
import { fetchOutageColor, TYPE } from 'esource-storm/utils/map-utils';
import { ChartDataPoint, CurrentWeatherForecast, ForecastData, Region } from 'interfaces/forecast';
import moment from 'moment-timezone';

const OUTAGE_COLOR = '#8BC34A';
const OUTAGE_COLORS = ['#8BC34A', '#2196f3' , '#FFAD42', '#F05D2B', '#5CD8FF', '#A63A50'];
const OUTAGE_NAME = 'Forecasted Outages';

interface RegionalChartData {
  color: string;
  data: any[];
  name: string;
  outageCount?: string;
}

export default class IndexController extends Controller {
  @service public config;
  @service public customization: Customization;
  @service public intl;
  @service public troveFetch: TroveFetch;

  public queryParams = ['selectedDate'];

  public hasOutageData: boolean;
  public hasWeatherData: boolean;
  public isCurrentForecastSaved: boolean = false;
  public legendLabelFormatter = function() {
    const { name, userOptions } = this;
    const { outageCount } = userOptions;
    return outageCount ? `${name} (${userOptions.outageCount})` : name;
  };
  public mapContext: SunstoneMapGeojsonComponent;
  public predictionChart: any;
  public predictionChartServiceArea: any;
  public serviceAreas: Grouping[] = [];
  public tooltipFormatterDaily = function() {
    const itemsMarkup = this.points.reduce((markup: string, point) => {
      markup = `${markup}
        <div class="layout-row layout-align-start-center">
          <h3 class="md-body-1 layout-margin-right">
            ${point.series.name}
          </h3>
          <h2 class="md-subhead layout-margin-right" style="color: ${point.color};">
            <b>${point.y}</b>
          </h2>
        </div>
      `;
      return markup;
    }, '');
    return `
      <div class="trove-chart-tooltip layout-padding-sm md-round md-primary">
        <div class="layout-column layout-padding-horizontal">
          <h2 class="layout-margin-bottom">${this.x}</h2>
          <div class="layout-column">${itemsMarkup}</div>
        </div>
      </div>
    `;
  };
  public tooltipFormatterHourly = function() {
    const { timezone, timezoneAbbr } = this.points[0].series.chart.time.options;
    const dt = moment.unix(this.x / 1000).tz(timezone).format('MMM. Do, ha');
    const header = `${dt} ${timezoneAbbr}`;
    const itemsMarkup = this.points.reduce((markup: string, point) => {
      markup = `${markup}
        <div class="layout-row layout-align-start-center">
          <h3 class="md-body-1 layout-margin-right">
            ${point.series.name}
          </h3>
          <h2 class="md-subhead layout-margin-right" style="color: ${point.color};">
            <b>${point.y.toFixed(2)}</b>
          </h2>
        </div>
      `;
      return markup;
    }, '');
    return `
      <div class="trove-chart-tooltip layout-padding-sm md-round md-primary">
        <div class="layout-column layout-padding-left layout-padding-right">
          <h2 class="layout-margin-bottom">${header}</h2>
          <div class="layout-column">${itemsMarkup}</div>
        </div>
      </div>
    `;
  };
  public xAxisFormatter = function() {
    const tz = this.chart.time.options.timezone;
    // Unix w/o milliseconds
    return moment.unix(this.value / 1000).tz(tz).format('ha');
  };

  @tracked public currentWeatherForecast: CurrentWeatherForecast;
  @tracked public dailyRunTime: string;
  @tracked public featureCollection: FeatureCollection = {
    features: [],
    type: TYPE
  };
  @tracked public forecasts: ForecastData[] = [];
  @tracked public groupings: Grouping[] = [];
  @tracked public hourlyPredictionCount: number;
  @tracked public hourlyRunTime: string;
  @tracked public predictedCount: number | null = null;
  @tracked public regionalChartData: RegionalChartData[] = [{
    color: OUTAGE_COLOR,
    data: [],
    name: OUTAGE_NAME
  }];
  @tracked public selectedDate: string = moment().format('YYYY-MM-DD');
  @tracked public selectedForecastViewOption: 0 | 1 = 1;
  @tracked public selectedGrouping: string = 'All';
  @tracked public selectedIndexOption: 0 | 1 = 1;
  @tracked public serviceAreaChartData: RegionalChartData[] = [{
    color: OUTAGE_COLOR,
    data: [],
    name: OUTAGE_NAME
  }];
  @tracked public timeSliderValue: number;
  @tracked public trailingChartData: Array<{ color: string; data: any[]; name: string; }> = [{
    color: OUTAGE_COLOR,
    data: [],
    name: OUTAGE_NAME
  }];

  public get showForecastDashboard() {
    return this.selectedIndexOption === 1;
  }

  public get isPredictionCountAvailable() {
    return this.predictedCount !== null;
  }

  public get mainIcon() {
    const { customization, predictedCount } = this;
    const { high, low, moderate } = customization.outageThresholds;
    if (predictedCount === null) return;
    if (predictedCount <= low) return {
      display: `Between 0 - ${low}`,
      icon: 'st-checkmark-circle',
      level: 'Low'
    };
    if (predictedCount <= moderate) return {
      display: `Between ${low + 1} - ${moderate}`,
      icon: 'st-notification-circle',
      level: 'Moderate'
    };
    if (predictedCount <= high) return {
      display: `Between ${moderate + 1} - ${high}`,
      icon: 'st-warning',
      level: 'High'
    };
    return {
     display: `${high + 1}+`,
     icon: 'st-notification',
     level: 'Severe'
   };
  }

  public get predictionChartTitle() {
    return `${this.hourlyPredictionCount}-Hour Forecast`;
  }

  public get predictionChartData() {
    const { regionalChartData, selectedForecastViewOption } = this;

    if (selectedForecastViewOption === 1) {
      return regionalChartData;
    } else {
      const totalData = regionalChartData.reduce((aggData: any[], { data }: RegionalChartData) => {
        if (aggData.length) {
          aggData.forEach((aggArray: RegionalChartData['data'], index: number) => {
            const [_time, totalCount] = aggArray;
            const regionalCount = data[index][1];
            aggArray[1] = totalCount + regionalCount;
          });
          return aggData;
        } else {
          return JSON.parse(JSON.stringify(data)); // Deep copy
        }
      }, []);
      return [{ color: OUTAGE_COLOR, data: totalData, name: OUTAGE_NAME }];
    }
  }

  public get displayIntervals() {
    const { data } = this.regionalChartData.firstObject as RegionalChartData;
    return data.length ? data.map(([timeStamp]) => timeStamp) : [];
  }

  public get xAxisTitle() {
    return `Hours (${this.customization.timezone.short})`;
  }

  public get formattedDailyRunTime() {
    return moment(this.dailyRunTime)
      .tz(this.customization.timezone.long)
      .format('MMMM Do h:mma');
  }

  public get isDailyRuntimeOutdated() {
    return moment(this.dailyRunTime).isBefore(moment().subtract(2, 'hours'));
  }

  public get formattedHourlyRunTime() {
    return moment(this.hourlyRunTime)
      .tz(this.customization.timezone.long)
      .format('MMMM Do h:mma');
  }

  public get isHourlyRuntimeOutdated() {
    return moment(this.hourlyRunTime).isBefore(moment().subtract(2, 'hours'));
  }

  public get groupingOptions() {
    // @ts-ignore
    return ['All', ...this.groupings.mapBy('name').sort()];
  }

  public get filteredServiceAreas() {
    if (this.selectedGrouping === 'All') {
      return this.serviceAreas;
    } else {
      // @ts-ignore
      const regionId = this.groupings.findBy('name', this.selectedGrouping).id;
      // @ts-ignore
      return this.serviceAreas.filterBy('parentId', +regionId);
    }
  }

  public useClick() {
    return true;
  }

  public useMouseover() {
    return true;
  }

  public generateForecasts() {
    const { forecastCardCount } = this.customization;
    const forecasts: ForecastData[] = [];

    for (let i = 0; i < forecastCardCount; i++) {
      forecasts.push({
        date: moment(this.selectedDate).add(i, 'days').format('YYYY-MM-DD'),
        isSelected: i === 0,
        outageCount: 0,
        outageDelta: 0,
        outageHistory: [],
        precipitation: '',
        temperature: '',
        windSpeed: ''
      });
    }

    return forecasts;
  }

  public generateWeatherData(forecastHash) {
    return Object
      .keys(forecastHash)
      .filter((key: string) => !!forecastHash[key]) // Filter out day(s) with no data
      .sort()
      .map((key: string) => {
        const { precipitation, temperature, windGustMph } = forecastHash[key];
        return {
          date: key,
          precipitation: precipitation.average.toFixed(1),
          temperature: temperature.average.toFixed(0),
          windSpeed: windGustMph.max.toFixed(0)
        };
      });
  }

  public appendOutageData(forecasts: ForecastData[], dailyResults) {
    forecasts.forEach((forecast: ForecastData) => {
      // @ts-ignore
      const dailyResult = dailyResults.findBy('date', forecast.date);
      forecast.outageCount = dailyResult ? dailyResult.totalOutages : 0;
      forecast.outageDelta = dailyResult ? dailyResult.totalOutageDelta : 0;
      forecast.outageHistory = dailyResult ? dailyResult.outageHistory : [];
    });
  }

  public setDailyRunTime(dailyResults) {
    const result = dailyResults.findBy('date', this.selectedDate);
    this.dailyRunTime = result.runTime;
  }

  public appendWeatherData(forecasts: ForecastData[], weatherResults) {
    forecasts.forEach((forecast: ForecastData) => {
      const weatherData = weatherResults.findBy('date', forecast.date);
      forecast.precipitation = weatherData.precipitation;
      forecast.temperature = weatherData.temperature;
      forecast.windSpeed = weatherData.windSpeed;
    });
  }

  public configureChartData() {
    // @ts-ignore
    const selectedForecast = this.forecasts.findBy('isSelected') as ForecastData;
    if (!selectedForecast) return;
    const history = [...selectedForecast.outageHistory];
    const data = history
      // @ts-ignore
      .sortBy('delta')
      .map(({ delta, outageCount }) => [delta, outageCount]);
    this.trailingChartData = [{ color: OUTAGE_COLOR, data, name: OUTAGE_NAME }];
  }

  public saveCurrentWeatherForecast(forecasts) {
    if (this.isCurrentForecastSaved) return;

    if (forecasts?.firstObject) {
      const { precipitation, temperature, windSpeed } = forecasts.firstObject;
      this.currentWeatherForecast = { precipitation, temperature, windSpeed };
    }

    this.isCurrentForecastSaved = true;
  }

  public addGeomDataToMap(groupings: Grouping[], isServiceArea: boolean = false) {
    const features = groupings.map((grouping: Grouping) => {
      const { displayName, geom, id, name } = grouping;
      const outageCount: number | null = this.retrieveOutageCount(name, isServiceArea);
      return buildGeoJson(geom.type, geom.coordinates, {
        borderColor: isServiceArea ? '#FFF' : '',
        bounds: true,
        componentInfoWindowProps: {
          componentName: 'grouping-info-window',
          componentProps: { grouping, outageCount }
        },
        extraProps: { grouping, isServiceArea },
        fillColor: fetchOutageColor(outageCount),
        name: titleize(displayName),
        weight: 0.54,
        zIndex: isServiceArea ? 3 : 1
      });
    });

    if (isServiceArea) {
      this.mapContext.map.data.addGeoJson({ features, type: TYPE });
    } else {
      this.featureCollection = { features, type: TYPE };
    }
  }

  public retrieveOutageCount(groupingName: string, isServiceArea: boolean) {
    const focusedChartData = isServiceArea
      ? this.serviceAreaChartData
      : this.regionalChartData;
    // @ts-ignore
    const chartData = focusedChartData.findBy('name', titleize(groupingName));
    if (!chartData) return null;
    return chartData.data.find(([timeStamp]) => {
      return timeStamp === this.timeSliderValue;
    })[1];
  }

  public highlightChartSeries(groupingName: string, chart: any) {
    chart.series.forEach((series) => {
      if (series.name === groupingName) {
        series.setState('hover');
      } else {
        series.setState('inactive', true);
      }
    });
  }

  public highlightMapGrouping(groupingName: string) {
    if (!this.mapContext?.map) return;
    this.mapContext.map.data.forEach((f) => {
      if (f.getProperty('name') !== groupingName) return;
      f.setProperty('borderColor', '#FFF');
      f.setProperty('zIndex', 2);
      f.setProperty('strokeWeight', 4);
    });
  }

  public fetchGroupings(name: string = 'region') {
    return this.store.query('grouping', {
      endpoint: 'findAllByGroupingTypeName',
      params: { name }
    });
  }

  public formatChartData(regions: Region[]) {
    return regions
      // @ts-ignore
      .sortBy('name')
      .map(({ name, outageCount, outagePrediction }: Region, index: number) => {
        const data = outagePrediction
          // @ts-ignore
          .sortBy('delta')
          .map((o: ChartDataPoint) => {
            return [moment(o.runtime).valueOf(), o.outageCount];
          });
        return {
          color: OUTAGE_COLORS[index],
          data,
          name: titleize(name),
          outageCount: this.intl.formatNumber(+outageCount.toFixed(2))
        };
      });
  }

  public removeRegionFromMap(regionId: string) {
    const mapData = this.mapContext.map.data;
    mapData.forEach((f) => {
      const grouping = f.getProperty('grouping');
      if (regionId === grouping.id) mapData.remove(f);
    });
  }

  @task
  public *fetchForecastData() {
    const { forecastCardCount, showServiceAreas } = this.customization;
    const forecasts = this.generateForecasts();
    // @ts-ignore
    const weatherResults = yield this.fetchWeatherForecast.perform();
    this.hasWeatherData = !!weatherResults;
    this.saveCurrentWeatherForecast(weatherResults);
    if (weatherResults) this.appendWeatherData(forecasts, weatherResults);
    // @ts-ignore
    const dailyResults = yield this.fetchDailyResults.perform();
    this.hasOutageData = !!dailyResults;

    if (dailyResults?.length) {
      this.appendOutageData(forecasts, dailyResults);
      this.setDailyRunTime(dailyResults);
    }

    this.forecasts = forecasts.slice(0, forecastCardCount);
    this.configureChartData();
    // @ts-ignore
    yield this.fetchFutureForecast.perform();
    // @ts-ignore
    yield this.fetchMapData.perform();

    if (showServiceAreas) {
      const serviceAreas = yield this.fetchGroupings('op_area');
      if (serviceAreas) this.serviceAreas = serviceAreas.toArray();
    }
  }

  @task
  public *fetchWeatherForecast() {
    const { config, troveFetch } = this;
    const url = `/weather/5day?start_date=${this.selectedDate}`;
    const namespace = config.get('trove.stormWeatherService.namespace');
    const serviceId = 'storm-weather-service';
    const promise = troveFetch.request(url, { namespace, serviceId });
    const errorMessage = 'Failed to retrieve weather forecast';
    const results = yield troveFetch.resolve(promise, { errorMessage });
    return results?.days && Object.keys(results.days).length
      ? this.generateWeatherData(results.days)
      : undefined;
  }

  @task
  public *fetchDailyResults() {
    const { config, customization, troveFetch } = this;
    const start = this.selectedDate;
    const end = moment(this.selectedDate)
      .add(customization.forecastCardCount, 'days')
      .format('YYYY-MM-DD');
    const url = `/results/daily?start=${start}&end=${end}`;
    const namespace = config.get('trove.stormInsightsService.namespace');
    const serviceId = 'storm-insights-service';
    const promise = troveFetch.request(url, { namespace, serviceId });
    const errorMessage = 'Failed to retrieve daily results';
    return yield troveFetch.resolve(promise, { errorMessage });
  }

  @task
  public *fetchFutureForecast() {
    const { config, troveFetch } = this;
    const url = '/results/hourly';
    const namespace = config.get('trove.stormInsightsService.namespace');
    const serviceId = 'storm-insights-service';
    const promise = troveFetch.request(url, { namespace, serviceId });
    const errorMessage = 'Failed to retrieve hourly predictions';
    const response = yield troveFetch.resolve(promise, { errorMessage });
    if (!response) return;
    this.hourlyPredictionCount = response.hourlyPredictionCount;
    this.predictedCount = response.totalOutages;
    this.hourlyRunTime = response.runTime;
    if (response.regions.length === 0) return;
    this.regionalChartData = this.formatChartData(response.regions);
    // Set first available timestamp
    this.timeSliderValue = this.regionalChartData[0].data[0][0];
  }

  @task
  public *fetchMapData() {
    const groupings = yield this.fetchGroupings();
    if (!groupings?.length) return;
    this.groupings = groupings.toArray();
    this.addGeomDataToMap(this.groupings);
  }

  @restartableTask
  public *fetchServiceAreaData(region: string) {
    this.selectedGrouping = region;
    this.addGeomDataToMap(this.groupings);
    if (region === 'All') return;
    // @ts-ignore
    yield this.fetchFutureRegionForecast.perform(region);
    // @ts-ignore
    const regionId = this.groupings.findBy('name', region).id;
    this.removeRegionFromMap(regionId);
    this.addGeomDataToMap(this.filteredServiceAreas, true);
  }

  @restartableTask
  public *fetchFutureRegionForecast(region) {
    const { config, troveFetch } = this;
    const url = `/results/hourly_service_center?region=${region}&targetType=hourly_service_center`;
    const namespace = config.get('trove.stormInsightsService.namespace');
    const serviceId = 'storm-insights-service';
    const promise = troveFetch.request(url, { namespace, serviceId });
    const errorMessage = 'Failed to retrieve regional hourly predictions';
    const response = yield troveFetch.resolve(promise, { errorMessage });
    if (!response || response.regions.length === 0) return;
    this.serviceAreaChartData = this.formatChartData(response.regions);
  }

  @action
  public selectCard(forecast: ForecastData) {
    // @ts-ignore
    this.forecasts.setEach('isSelected', false);
    set(forecast, 'isSelected', true);
    this.configureChartData();
  }

  @action
  public updateMapProps(timeStamp: number) {
    const { groupings, selectedGrouping } = this;
    this.timeSliderValue = timeStamp;
    this.addGeomDataToMap(groupings);
    if (this.selectedGrouping === 'All') return;
    next(this, () => {
      // @ts-ignore
      const regionId = groupings.findBy('name', selectedGrouping).id;
      this.removeRegionFromMap(regionId);
      this.addGeomDataToMap(this.filteredServiceAreas, true);
    });
  }

  @action
  public onEnterToggle(id: string, { target }: KeyboardEvent) {
    if ((target as HTMLElement).id !== id) return;
    if (id === 'toggle-hourly') this.selectedIndexOption = 0;
    if (id === 'toggle-daily') this.selectedIndexOption = 1;
    if (id === 'toggle-all') this.selectedForecastViewOption = 0;
    if (id === 'toggle-regions') this.selectedForecastViewOption = 1;
  }

  @action
  public onEnterForecastCard(forecast, { target }: KeyboardEvent) {
    if ((target as HTMLElement).id !== forecast.date) return;
    this.selectCard(forecast);
  }

  @action
  public onSeriesMouseover(chart: any, { target }: any) {
    this.highlightMapGrouping(target.name);
    this.highlightChartSeries(target.name, chart);
  }

  @action
  public onMapMouseover({ feature }) {
    feature.setProperty('borderColor', '#FFF');
    feature.setProperty('zIndex', 2);
    feature.setProperty('strokeWeight', 4);
    const chart = feature.getProperty('isServiceArea')
      ? this.predictionChartServiceArea
      : this.predictionChart;
    this.highlightChartSeries(feature.getProperty('name'), chart);
  }

  @action
  public onSeriesMouseout(chart: any) {
    chart.series.forEach((s) => s.setState('hover'));
    this.onMapMouseout();
  }

  @action
  public onMapMouseout() {
    if (!this.mapContext?.map) return;
    this.mapContext.map.data.forEach((f) => {
      const isServiceArea = f.getProperty('isServiceArea');
      f.setProperty('strokeWeight', 2);

      if (!isServiceArea) {
        f.setProperty('borderColor', undefined);
        f.setProperty('zIndex', undefined);
      }
    });
  }

  @action
  public selectRegion(f: any) {
    const isServiceArea = f.getProperty('isServiceArea');
    if (isServiceArea) return;
    const grouping = f.getProperty('grouping');
    this.fetchServiceAreaData.perform(grouping.name);
  }
}
