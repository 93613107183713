self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: "silence", matchId: "ember.built-in-components.import" },
    { handler: "silence", matchId: "ember.globals-resolver" },
    { handler: "silence", matchId: "deprecated-run-loop-and-computed-dot-access" },
    { handler: "silence", matchId: "ember-test-waiters-legacy-module-name" },
    { handler: "silence", matchId: "ember-metal.get-with-default" },
    { handler: "silence", matchId: "ember-keyboard.first-responder-inputs" },
    { handler: "silence", matchId: "ember-simple-auth.initializer.setup-session-restoration" },
    { handler: "silence", matchId: "ember-simple-auth.mixins.application-route-mixin" },
    { handler: "silence", matchId: "ember-simple-auth.events.session-service" },
    { handler: "silence", matchId: "this-property-fallback" },
    { handler: "silence", matchId: "application-controller.router-properties" },
    { handler: "silence", matchId: "ember-utils.try-invoke" },
    { handler: "silence", matchId: "ember-modifier.use-modify" },
    { handler: "silence", matchId: "ember-modifier.no-args-property" },
    { handler: "silence", matchId: "ember-modifier.no-element-property" },
    { handler: "silence", matchId: "ember-keyboard.old-propagation-model" },
    { handler: "silence", matchId: "implicit-injections" }
  ]
};
