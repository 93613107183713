import ApplicationJsonApi from '@trovedata/sunstone-ui-commons/adapters/application-json-api';

export default class Grouping extends ApplicationJsonApi {
  public getFallbackNamespace() {
    return this.config.get('trove.stormGeoService.namespace');
  }

  public getTargetBackendServiceId() {
    return 'storm-geo-service';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'grouping': Grouping;
  }
}
