import Service from '@ember/service';

interface OutageThresholds {
  high: number;
  low: number;
  moderate: number;
}

export default class Customization extends Service {
  public forecastCardCount: number = 5;
  public outageThresholds: OutageThresholds = {
    high: 125,
    low: 25,
    moderate: 50
  };
  public showServiceAreas: boolean = false;
  public timezone: { long: string; short: string; } = {
    long: 'America/New_York',
    short: 'EST'
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'customization': Customization;
  }
}
