// Stroke Weight
export const ACTIVE_STROKE_WEIGHT = 5;
export const STROKE_WEIGHT = 3;

// Colors
export const DEFAULT_PURPLE = '#9370DB';
const RANGE1 = '#0047AB';
const RANGE2 = '#89CFF0';
const RANGE3 = '#F28C28';
const RANGE4 = '#C70039';
const RANGE5 = '#800020';

// Misc
export const TYPE = 'FeatureCollection';

// Functions
export function fetchOutageColor(count: number | null) {
  if (count === null) return DEFAULT_PURPLE;
  if (count === 0) return RANGE1;
  if (count <= 3) return RANGE2;
  if (count <= 6) return RANGE3;
  if (count <= 9) return RANGE4;
  return RANGE5;
}
