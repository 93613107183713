import EmberRouter from '@ember/routing/router';
import { inject as service } from '@ember/service';
import CommonsRouter from '@trovedata/sunstone-ui-commons/router';
import config from 'esource-storm/config/environment';

export default class Router extends EmberRouter {
  @service public currentUser;

  location = config.locationType;
  rootURL = config.rootURL;

  init() {
    if (config.environment !== 'production') return;
    this.addObserver('currentUser.user', this, () => {
      this.executeTracking();
    });
    this.on('didTransition', () => {
      if (!this.currentUser.user) return;
      this.executeTracking();
    })
  }

  executeTracking() {
    const { metadata, user } = this.currentUser;
    const { email, id, lastLogin, username } = user;
    const organization = metadata?.settings?.findBy('name', 'Organization');
    window.Appcues.page();
    window.Appcues.identify(id, {
      email,
      lastLogin,
      organization: organization ? organization.value : null,
      username
    });
  }
}

Router.map(function() {
  CommonsRouter(this);
  this.route('storm', { path: '/' }, function() {

  });
});
