import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import TroveFetch from '@trovedata/sunstone-ui-commons/services/trove-fetch';
import { task } from 'ember-concurrency-decorators';
import Customization from 'esource-storm/services/customization';

export default class StormRoute extends Route {
  @service public config;
  @service public customization: Customization;
  @service public troveFetch: TroveFetch;

  public async model() {
    // Retrieve config before UI resolves
    // @ts-ignore
    await this.getConfiguration.perform();
    return {};
  }

  public setConfigurations(stormConfig) {
    if (!stormConfig) return;
    const key1 = 'com.esource.storm-ui.outage-thresholds.low';
    const key2 = 'com.esource.storm-ui.outage-thresholds.moderate';
    const key3 = 'com.esource.storm-ui.outage-thresholds.high';
    const key4 = 'com.esource.storm-ui.timezone.long';
    const key5 = 'com.esource.storm-ui.timezone.short';
    const key6 = 'com.esource.storm-ui.forecast-card.count';
    const key7 = 'com.esource.storm-ui.service-areas.enabled';
    this.customization.outageThresholds = {
      high: stormConfig.source[key3],
      low: stormConfig.source[key1],
      moderate: stormConfig.source[key2]
    };
    this.customization.timezone = {
      long: stormConfig.source[key4],
      short: stormConfig.source[key5]
    };
    this.customization.forecastCardCount = stormConfig.source[key6];
    this.customization.showServiceAreas = stormConfig.source[key7];
  }

  @task
  public *getConfiguration() {
    const { config, troveFetch } = this;
    const host = config.get('trove.apiGatewayBaseUrl');
    const url = `/spring-cloud-config-server/storm-ui/${config.get('activeProfiles')}`;
    const promise = troveFetch.request(url, { host, namespace: null });
    const configHash = yield troveFetch.resolve(promise);
    const stormConfig = configHash?.propertySources?.find(({ name }) => {
      return name.includes('storm-ui');
    });
    this.setConfigurations(stormConfig);
  }
}
